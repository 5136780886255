<template>
  <div>
    <search-sim-card
      @submit="submitSearch"
      :agent-id="agentId"
    />

    <div class="iotplt-table-operation-buttons">
      <a-space>
        <a-dropdown placement="bottomCenter">
          <a-menu slot="overlay" @click="handleBatchOperation">
            <a-menu-item
              v-for="option in batchOperationOptions"
              :key="option.slug"
            >
              {{ option.name }}
            </a-menu-item>
          </a-menu>
          <a-button type="primary"> 批量操作 <a-icon type="down" /> </a-button>
        </a-dropdown>
        <a-button type="primary" @click="importData">导入</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="iccid"
    >
      <span slot="status_title">
        <a-tooltip>
          <template slot="title">
            定时同步
          </template>
          状态
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="cycle_total_title">
        <a-tooltip>
          <template slot="title">
            定时同步
          </template>
          周期可用量
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="cycle_usage_title">
        <a-tooltip>
          <template slot="title">
            定时同步
          </template>
          周期已用量
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="cycle_left_title">
        <a-tooltip>
          <template slot="title">
            定时同步
          </template>
          周期剩余用量
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="actions" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="isHasEditPermission">
              <a @click="showEditModal(record.product_type_slug, record.id)">编辑</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <import-sim-cards
      v-if="isShowImportModal"
      :visible.sync="isShowImportModal"
      :iccids="selectedRowKeys"
    />

    <!-- 编辑模态框 -->
    <edit-sim-cards
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
    />

    <!-- 删除模态框 -->
    <delete-sim-cards
      v-if="isShowDeleteModal"
      :visible.sync="isShowDeleteModal"
      :iccids="selectedRowKeys"
      :agent-id="agentId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findSimCards } from '@/api/oc_sim_card'
import Pagination from '@/components/Pagination'
import SearchSimCard from '@/views/oc_sim_cards/Search'
import { findOcSimCardBatchOperationOptions } from '@/api/batch_operation'

export default {
  name: 'SimCardList',
  components: {
    SearchSimCard,
    Pagination,
    ImportSimCards: () => import('@/views/oc_sim_cards/Import'),
    EditSimCards: () => import('@/views/oc_sim_cards/Edit'),
    DeleteSimCards: () => import('@/views/batch_operations/DeleteSimCards')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowInfoModal: false, // 是否显示详情弹窗
      isShowEditModal: false, // 是否显示编辑套餐表单弹窗
      showingId: 0, // 正在查看详情的记录id
      editingId: 0, // 正在编辑详情的记录id
      batchOperationOptions: [], // 批量操作选项
      isShowImportModal: false, // 是否显示导入模态框
      isShowBatchModifyModal: false, // 是否显示批量修改模态框
      isShowDeleteModal: false, // 是否显示批量删除模态框
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
    this.fetchAgentSimCardBatchOperationOptions()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false
        },
        {
          title: '手机号码',
          dataIndex: 'msisdn'
        },
        {
          title: 'IMSI',
          dataIndex: 'imsi'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          slots: { title: 'status_title' },
          dataIndex: 'status'
        },
        {
          slots: { title: 'cycle_total_title' },
          dataIndex: 'cycle_total'
        },
        {
          slots: { title: 'cycle_usage_title' },
          dataIndex: 'cycle_usage'
        },
        {
          slots: { title: 'cycle_left_title' },
          dataIndex: 'cycle_left'
        },
        {
          title: '采购套餐',
          dataIndex: 'package'
        },
        {
          title: '采购周期',
          dataIndex: 'cycles'
        },
        {
          title: '到期时间',
          dataIndex: 'end_date'
        },
        {
          title: '采购单价',
          dataIndex: 'price'
        },
        {
          title: '采购时间',
          dataIndex: 'order_date'
        },
        {
          title: '沉默期',
          dataIndex: 'silent_period'
        },
        {
          title: '项目',
          dataIndex: 'project'
        },
        {
          title: '分组',
          dataIndex: 'group_name'
        },
        {
          title: '设备序列号',
          dataIndex: 'device_sn'
        },
        {
          title: '设备名称',
          dataIndex: 'device_name'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '供应商',
          dataIndex: 'supplier'
        },
        {
          title: '数据更新时间',
          dataIndex: 'updated_at'
        },
        {
          title: '操作',
          dataIndex: 'actions',
          fixed: 'right',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    },

    isHasEditPermission() {
      return hasPermission('customer/oc_sim_cards')
    },

    agentId() {
      if (this.$route.query.agent_id) {
        return parseInt(this.$route.query.agent_id)
      }

      return this.$store.getters.userAgentId
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    handleBatchOperation(e) {
      switch (e.key) {
        case 'delete_oc_sim_cards':
          this.isShowDeleteModal = true
          break
        case 'batch_modify' :
          this.isShowBatchModifyModal = true
          break
      }
    },

    showEditModal(productTypeSlug, recordId) {
      this.isShowEditModal = true
      this.editingId = recordId
    },

    showInfoModal(id) {
      this.showingId = id
      this.isShowInfoModal = true
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchAgentSimCardBatchOperationOptions() {
      findOcSimCardBatchOperationOptions().then((res) => {
        if (res.code === 0) {
          this.batchOperationOptions = res.data
        }
      })
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findSimCards(Object.assign({ agent_id: this.agentId },
        this.query, this.sort)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },
    importData() {
      this.isShowImportModal = true
    }
  }
}
</script>
