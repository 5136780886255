import request from '@/utils/request'

// 查询客户的卡
export function findSimCards(data) {
  return request({
    url: `/customer/oc_sim_cards`,
    method: 'post',
    data
  })
}

// 查询 sim_card 卡基本信息
export function findSimCardBaseInfo(id) {
  return request({
    url: `/customer/oc_sim_cards/${id}/form`,
    method: 'get'
  })
}

// 导入卡
export function importSimCards(data) {
  return request({
    url: `/customer/oc_import_sim_cards`,
    method: 'post',
    data
  })
}

// 编辑卡
export function editSimCards(id, data) {
  return request({
    url: `/customer/oc_sim_cards/${id}`,
    method: 'put',
    data
  })
}

// 分销商批量删除卡
export function deleteSimCards(data) {
  return request({
    url: `/customer/oc_import_sim_cards/batch`,
    method: 'delete',
    data
  })
}

// 客户供应商选项数据
export function findSuppliersOptions(params) {
  return request({
    url: `/customer/oc_supplier/options`,
    method: 'get',
    params: params
  })
}
